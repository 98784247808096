import React, { useEffect } from 'react';
import Image from 'next/image';
import { Nav } from '@/components/nav';
import { Meta } from '@/components/meta';
import { Container } from '@/components/container';
import { DownloadApp } from '@/components/download-app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AffirmationBlocks from '@/components/affirmations-blocks';
import {
  faSearch,
  faSpinner,
  faThumbsUp,
  faThumbsDown,
  faRefresh,
  faVolumeLow,
} from '@fortawesome/free-solid-svg-icons';
import cssClasses from '@/styles/Affirmation.module.css';
import AffirmationImage from '@/assets/affirmation-backdrops/cup.webp';
import affirmtionImg1 from './assets/affirmation-img.webp';
import affirmtionImg2 from './assets/affirmation-img2.webp';
import affirmtionImg3 from './assets/affirmation-img3.webp';
import affirmtionImg4 from './assets/affirmation-img4.webp';
import affirmtionImg5 from './assets/affirmation-img5.webp';
import affirmtionImg6 from './assets/affirmation-img6.webp';
import { useMobileResponsive } from '@/hooks';
import axios from 'axios';
import Blackfriday from '@/components/Blackfridaybar';

const priorities = [
  { name: `Career`, value: `work`, emoji: `📚` },
  { name: `Family`, value: `family`, emoji: `👪` },
  { name: `Love`, value: `love`, emoji: `❤️` },
  { name: `Social`, value: `friends`, emoji: `🧑‍🤝‍🧑` },
  { name: `Self`, value: `self`, emoji: `🙌` },
];

function Loading() {
  return <FontAwesomeIcon icon={faSpinner} className="fa-spin text-white" />;
}

export default function Affirmation() {
  type AffirmationType = {
    affirmation: string;
  };

  const imageContainerRef = React.useRef<HTMLDivElement>(null);
  const [error, setError] = React.useState(``);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasSpeechSynth, setHasSpeechSynth] = React.useState(false);
  const [imageContainerWidth, setImageContainerWidth] = React.useState(0);
  const [formData, setFormData] = React.useState<{ [key: string]: any }>({});

  const [affirmation, setAffirmation] = React.useState<AffirmationType | null>(
    null,
  );

  const getNewAffirmation = async (question: string, priority: string) => {
    setIsLoading(() => true);

    const data = JSON.stringify({
      query: question,
    });
    const config = {
      method: `post`,
      maxBodyLength: Infinity,
      url: `https://level-ai-personal-coach.level.game/api/level/affirmation`,
      headers: {
        'Content-Type': `application/json`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setError(() => ``);
        setIsLoading(() => false);
        setAffirmation(() => response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const sendFeedback = async (id: number, feedback: string, query: string) => {
    setIsLoading(() => true);
    const raw = await fetch(`/api/affirmation-feedback`, {
      method: `PUT`,
      body: JSON.stringify({ id, feedback, query }),
    });

    if (raw.ok) {
      setIsLoading(() => false);
      return { error: false };
    }

    setIsLoading(() => false);
    return { error: true };
  };

  React.useEffect(() => {
    const windowResizeListener = () => {
      // find and update image container width
      const imgContainer = imageContainerRef.current;
      const width = imgContainer?.getBoundingClientRect().width;
      setImageContainerWidth(width || 0);
    };

    windowResizeListener();
    window.addEventListener(`resize`, windowResizeListener);

    if (
      typeof window.speechSynthesis?.speak === `function` &&
      typeof window.SpeechSynthesisUtterance === `function`
    ) {
      setHasSpeechSynth(() => true);
    }

    return () => {
      window.removeEventListener(`resize`, windowResizeListener);
    };
  }, []);

  const { isMobile } = useMobileResponsive();

  return (
    <>
      <Nav />
      <Meta
        title="Affirmation | Level - Supercharge your mind!"
        desc="Positive thoughts lead to positive actions! Try out positive affirmations to balance your career, family, and life in general."
        url="https://level.game/affirmation"
      />

      <Container>
        <>
          <div className="p-4">
            {error && (
              <div
                className={[
                  `max-w-7xl`,
                  `mx-auto`,
                  `p-4`,
                  `text-red-500`,
                  `font-bold`,
                  `bg-red-100`,
                  `rounded-md`,
                  `border-2`,
                  `border-red-500`,
                ].join(` `)}
              >
                {error}
              </div>
            )}
          </div>

          <div
            className={[
              `p-4`,
              `md:p-4`,
              `my-4`,
              `md:my-2`,
              `max-w-7xl`,
              `m-auto`,
              `flex`,
              `flex-col`,
              `md:flex-row`,
              `gap-10`,
            ].join(` `)}
          >
            <div className={[`w-full`, `md:w-1/2`, `mt-[4rem]`].join(` `)}>
              <AffirmationBlocks.Title />
              <AffirmationBlocks.Subtitle />

              <form
                className={[].join(` `)}
                onChange={(ev) => {
                  const formData = new FormData(ev.currentTarget);
                  const question = formData.get(`question`);
                  const priority = formData.get(`priority`);

                  setFormData((prev) => ({ ...prev, question, priority }));
                }}
                onSubmit={(ev) => {
                  ev.preventDefault();
                  getNewAffirmation(formData.question, formData.priority);
                }}
              >
                <div
                  className={[
                    `flex`,
                    `border-2`,
                    `border-[#7356E8]`,
                    `rounded-md`,
                    `overflow-hidden`,
                  ].join(` `)}
                >
                  <input
                    required
                    name="question"
                    placeholder="Type a thought that's bothering you…"
                    autoComplete="off"
                    className={[`grow`, `px-4`, `py-2`].join(` `)}
                  />
                  <span className="border max-h-[50%]" />
                  <button
                    disabled={isLoading}
                    type="submit"
                    className={[`bg-white`, `px-4`].join(` `)}
                  >
                    <FontAwesomeIcon
                      icon={isLoading ? faSpinner : faSearch}
                      className={isLoading ? `fa-spin` : ``}
                    />
                  </button>
                </div>

                <div
                  className={[
                    `p-4`,
                    `border-2`,
                    `border-dashed`,
                    `border-[#7356E8] text-white`,
                    `border-t-0`,
                    `rounded-md`,
                    `rounded-t-none`,
                    formData.question ? `block` : `hidden`,
                  ].join(` `)}
                >
                  <h3>What part of your life does this belong to?</h3>

                  <div
                    className={[
                      `flex`,
                      `gap-4`,
                      `justify-between`,
                      `mt-4`,
                    ].join(` `)}
                  >
                    {priorities.map((priority) => (
                      <label
                        key={priority.name}
                        className={[
                          `relative`,
                          `border`,
                          `rounded-lg`,
                          `overflow-hidden text-black`,
                        ].join(` `)}
                      >
                        <input
                          required
                          type="radio"
                          name="priority"
                          value={priority.value}
                          className={[
                            `absolute`,
                            `inset-0`,
                            `h-full`,
                            `w-full`,
                            `opacity-0`,
                            `cursor-pointer`,
                            cssClasses.priority_checkbox,
                          ].join(` `)}
                        />
                        <span
                          className={[
                            `flex`,
                            `flex-col`,
                            `gap-1`,
                            `justify-center`,
                            `items-center`,
                            `text-center`,
                            `p-2`,
                            `md:p-4`,
                            `md:min-w-[10ch]`,
                            `text-xs`,
                            `md:text-[0.95rem]`,
                            `bg-white`,
                          ].join(` `)}
                        >
                          {priority.emoji}
                          <span />
                          {priority.name}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>

                {formData.question && formData.priority && (
                  <button
                    style={{ minWidth: `12ch` }}
                    disabled={isLoading}
                    className={[
                      `mt-4`,
                      `mb-10`,
                      `px-8`,
                      `py-4`,
                      `bg-[#7356E8]`,
                      `rounded-lg`,
                      `font-bold`,
                      `text-white`,
                      `text-lg`,
                    ].join(` `)}
                  >
                    {isLoading ? <Loading /> : `Submit`}
                  </button>
                )}
              </form>
            </div>

            <div className={[`md:w-1/2`].join(``)}>
              <div
                ref={imageContainerRef}
                className={[
                  `w-full`,
                  `max-w-lg`,
                  `px-4`,
                  `mx-auto`,
                  `flex`,
                  `items-center`,
                  `justify-center`,
                  `flex-col`,
                  `border-8`,
                  `border-[#BFB6E5]`,
                  `relative`,
                  `rounded-3xl`,
                  `overflow-hidden`,
                ].join(` `)}
                style={{ height: imageContainerWidth || `auto` }}
              >
                <Image
                  src={AffirmationImage.src}
                  alt="Affirmation - Level"
                  layout="fill"
                  placeholder="blur"
                  objectFit="cover"
                  blurDataURL={AffirmationImage.blurDataURL}
                />

                {affirmation && (
                  <div
                    className={[
                      `z-10`,
                      `absolute`,
                      `inset-0`,
                      `p-4`,
                      `bg-[#000000be]`,
                      `flex`,
                      `items-center`,
                      `justify-center`,
                      `flex-col`,
                      `gap-y-6`,
                      `md:text-2xl`,
                      `text-white`,
                      `font-bold`,
                    ].join(` `)}
                  >
                    <div className="text-center">
                      {/* render returned affirmation */}
                      <span className="text-3xl">“</span>
                      {affirmation.affirmation ||
                        `Lorem ipsum dolor set amet. `}
                      <span className="text-3xl absolute">”</span>
                    </div>

                    <div className="flex gap-2 flex-wrap">
                      <button
                        disabled={isLoading}
                        className={[
                          `px-2`,
                          `py-1`,
                          `bg-[#7559e8]`,
                          `shadow-lg`,
                          `grow`,
                          `border`,
                          `border-white`,
                          `rounded-lg`,
                          `text-white`,
                          `font-bold`,
                          `opacity-100`,
                        ].join(` `)}
                        onClick={() =>
                          getNewAffirmation(
                            formData.question,
                            formData.priority,
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faRefresh} />
                      </button>

                      {/* <button
                        disabled={isLoading || affirmation != ``}
                        className={[
                          `px-2`,
                          `py-1`,
                          `bg-[#7559e8]`,
                          `shadow-lg`,
                          `grow`,
                          `border`,
                          `border-white`,
                          `rounded-lg`,
                          `text-white`,
                          `font-bold`,
                          `opacity-100`,
                          `disabled:bg-gray-400`,
                        ].join(` `)}
                        onClick={() => {
                          if (affirmation != ``) {
                            sendFeedback(
                              affirmation.id,
                              `WORKED`,
                              formData.question,
                            );
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faThumbsUp} />
                      </button>

                      <button
                        disabled={isLoading || affirmation != ``}
                        className={[
                          `px-2`,
                          `py-1`,
                          `bg-[#7559e8]`,
                          `shadow-lg`,
                          `grow`,
                          `border`,
                          `border-white`,
                          `rounded-lg`,
                          `text-white`,
                          `font-bold`,
                          `opacity-100`,
                          `disabled:bg-gray-400`,
                        ].join(` `)}
                        onClick={() => {
                          if (affirmation != ``) {
                            sendFeedback(
                              affirmation.id,
                              `WORKED`,
                              formData.question,
                            );
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faThumbsDown} />
                      </button>

                      <button
                        disabled={
                          isLoading || affirmation != `` || !hasSpeechSynth
                        }
                        className={[
                          `px-2`,
                          `py-1`,
                          `bg-[#7559e8]`,
                          `shadow-lg`,
                          `grow`,
                          `border`,
                          `border-white`,
                          `rounded-lg`,
                          `text-white`,
                          `font-bold`,
                          `opacity-100`,
                          `disabled:bg-gray-400`,
                        ].join(` `)}
                        onClick={() => {
                          window.speechSynthesis.speak(
                            new window.SpeechSynthesisUtterance(
                              affirmation.affirmation,
                            ),
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faVolumeLow} />
                      </button> */}
                    </div>
                  </div>
                )}

                {isLoading && (
                  <div
                    className={[
                      `z-10`,
                      `absolute`,
                      `inset-0`,
                      `p-4`,
                      `bg-[#000000be]`,
                      `flex`,
                      `items-center`,
                      `justify-center`,
                      `text-white`,
                    ].join(` `)}
                  >
                    {isLoading && <Loading />}
                  </div>
                )}
              </div>
            </div>
          </div>

          {/*  */}
          <div
            className={[
              `p-4`,
              `md:p-4 text-white`,
              `my-4`,
              `md:my-2`,
              `max-w-7xl`,
              `m-auto`,
              `max-w-7xl`,
              `gap-10`,
            ].join(` `)}
          >
            <span>
              <h2 className="font-bold text-3xl pb-4">
                Why You Should Practice Daily Affirmations: A Scientific
                Perspective
              </h2>
              When it comes to your mental health and personal growth,
              practicing affirmations on a daily basis can create a huge impact.
              The positive, empowering statements, when repeated on a daily
              basis, can make lasting changes to the way you think and feel. But
              why should affirmations be a part of your everyday routine? Let’s
              see why.
            </span>
            {/* 1 */}
            <div className={[`pt-16 block`, `md:flex`].join(` `)}>
              <div
                className={[
                  `md:w-[50vw] md:p-4 md:pr-9 `,
                  `w-full m-auto`,
                ].join(` `)}
              >
                <h2
                  className={[
                    `md:font-bold md:text-2xl pb-1`,
                    `font-semibold text-lg`,
                  ].join(` `)}
                >
                  1. Rewiring your brain
                </h2>
                <div className={[`md:pl-6`].join(` `)}>
                  Your brain is an incredibly adaptable organ, capable of
                  forming new connections and pathways—a phenomenon known as
                  neuroplasticity. When you practice daily affirmations,
                  you&apos;re essentially training your brain to adopt a more
                  positive perspective. Over time, these repeated positive
                  statements can help rewire your brain, reducing the tendency
                  towards negativity and enhancing your capacity for positivity.
                </div>
              </div>
              <div
                className={[`p-3 pl-4 m-auto md:w-[50vw]`, `w-full`].join(` `)}
              >
                <img src={affirmtionImg2.src} alt="img" />
              </div>
            </div>
            {/* 2 */}
            <div className={[` block`, `md:flex`].join(` `)}>
              <div
                style={{ display: isMobile ? `none` : `block` }}
                className={[
                  `p-3 pl-4 m-auto md:w-[50vw]`,
                  `w-full`,
                  `sm:none`,
                ].join(` `)}
              >
                <img src={affirmtionImg1.src} alt="img" />
              </div>
              <div
                className={[`md:w-[50vw] md:p-4`, `w-full m-auto`].join(` `)}
              >
                <h2
                  className={[
                    `md:font-bold md:text-2xl pb-1`,
                    `font-semibold text-lg`,
                  ].join(` `)}
                >
                  2. Boosting self-esteem and confidence
                </h2>
                <div className={[`md:pl-6`].join(` `)}>
                  Negative self-talk and self-doubt can significantly undermine
                  our self-esteem and confidence. Daily affirmations serve as a
                  counterbalance to these negative thoughts. By consistently
                  affirming your abilities and strengths, you can cultivate a
                  more positive self-image and boost your confidence.
                </div>
                <div
                  style={{ display: !isMobile ? `none` : `block` }}
                  className={[`p-3 pl-4 m-auto md:w-[50vw]`, `w-full`].join(
                    ` `,
                  )}
                >
                  <img src={affirmtionImg1.src} alt="img" />
                </div>
              </div>
            </div>
            {/* 3 */}
            <div className={[`block`, `md:flex`].join(` `)}>
              <div
                className={[`md:w-[50vw] md:p-4`, `w-full m-auto`].join(` `)}
              >
                <h2
                  className={[
                    `md:font-bold md:text-2xl pb-1`,
                    `font-semibold text-lg`,
                  ].join(` `)}
                >
                  3. Reducing stress and anxiety
                </h2>
                <div className={[`md:pl-6`].join(` `)}>
                  Stress and anxiety often stem from negative thought patterns
                  and worries about the future. Affirmations can help anchor you
                  in the present moment and promote a more positive outlook,
                  thereby reducing feelings of everyday stress and anxiety.
                </div>
              </div>
              <div
                className={[`p-3 pl-4 m-auto md:w-[50vw]`, `w-full`].join(` `)}
              >
                <img src={affirmtionImg4.src} alt="img" />
              </div>
            </div>
            {/* 4 */}
            <div className={[`block`, `md:flex`].join(` `)}>
              <div
                style={{ display: isMobile ? `none` : `block` }}
                className={[`p-3 pl-4 m-auto md:w-[50vw]`, `w-full`].join(` `)}
              >
                <img src={affirmtionImg3.src} alt="img" />
              </div>
              <div
                className={[`md:w-[50vw] md:p-4`, `w-full m-auto`].join(` `)}
              >
                <h2
                  className={[
                    `md:font-bold md:text-2xl pb-1`,
                    `font-semibold text-lg`,
                  ].join(` `)}
                >
                  4. Promoting healthier habits
                </h2>
                <div className={[`md:pl-6`].join(` `)}>
                  Affirmations can also play a role in promoting healthier
                  habits. For instance, affirmations related to health and
                  wellness (&quot;I choose to nourish my body with healthy
                  foods&quot; or &quot;I enjoy exercising and keeping my body
                  fit&quot;) can motivate you to make better decisions and help
                  you stay committed to a healthier lifestyle.
                </div>
                <div
                  style={{ display: !isMobile ? `none` : `block` }}
                  className={[`p-3 pl-4 m-auto md:w-[50vw]`, `w-full`].join(
                    ` `,
                  )}
                >
                  <img src={affirmtionImg3.src} alt="img" />
                </div>
              </div>
            </div>
            {/* 5 */}
            <div className={[`block`, `md:flex`].join(` `)}>
              <div
                className={[`md:w-[50vw] md:p-4`, `w-full m-auto`].join(` `)}
              >
                <h2
                  className={[
                    `md:font-bold md:text-2xl pb-1`,
                    `font-semibold text-lg`,
                  ].join(` `)}
                >
                  5. Improving Relationships
                </h2>
                <div className={[`md:pl-6`].join(` `)}>
                  Words of affirmation can also enhance your relationships.
                  Expressing positive sentiments and appreciation towards your
                  loved ones can strengthen your bonds, improve communication,
                  and foster a more positive environment.
                </div>
              </div>
              <div
                className={[`p-3 pl-4 m-auto md:w-[50vw]`, `w-full`].join(` `)}
              >
                <img src={affirmtionImg5.src} alt="img" />
              </div>
            </div>
            {/* 6 */}
            <div className={[`block`, `md:flex`].join(` `)}>
              <div
                style={{ display: isMobile ? `none` : `block` }}
                className={[
                  `p-3 pl-4 m-auto md:w-[50vw]`,
                  `w-full m-auto`,
                ].join(` `)}
              >
                <img src={affirmtionImg6.src} alt="img" />
              </div>
              <div
                className={[`md:w-[50vw] md:p-4`, `w-full m-auto`].join(` `)}
              >
                <h2
                  className={[
                    `md:font-bold md:text-2xl pb-1`,
                    `font-semibold text-lg`,
                  ].join(` `)}
                >
                  6. Empowering Women
                </h2>
                <div className={[`md:pl-6`].join(` `)}>
                  For women, daily affirmations can be particularly empowering.
                  Affirmations that focus on self-worth, strength, and
                  capability can help challenge societal expectations and
                  internalized negative beliefs, fostering a sense of
                  empowerment and self-belief. You don’t have to invest a lot of
                  time to incorporate daily affirmations into your routine.
                </div>
                <div
                  style={{ display: !isMobile ? `none` : `block` }}
                  className={[`p-3 pl-4 m-auto md:w-[50vw]`, `w-full`].join(
                    ` `,
                  )}
                >
                  <img src={affirmtionImg6.src} alt="img" />
                </div>
              </div>
            </div>
            It can be as simple as starting your day with a positive statement
            or taking a few moments to affirm your worth and capabilities
            throughout the day. The key is consistency. Like any other form of
            training, the benefits of affirmations are most evident when
            they&apos;re practiced regularly. So why not start today? The
            journey to a more positive and empowered self is just an affirmation
            away.
          </div>
          {/*  */}
          {/* <AdBanner
            data-ad-slot="7434970023"
            data-ad-format="auto"
            data-full-width-responsive="true"
          /> */}

          <div className="mt-40">
            <DownloadApp />
          </div>
        </>
      </Container>
    </>
  );
}
